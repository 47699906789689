import { useEffect, useState } from "react"

export type SystemColorScheme = "light" | "dark"

function getPreferredColorScheme(): "light" | "dark" {
  if (typeof window === "undefined") return "light"

  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark"
  }
  return "light"
}

export function useSystemColorScheme() {
  const [systemColorScheme, setSystemColorScheme] = useState<SystemColorScheme>(
    () => getPreferredColorScheme()
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
    const listener = (e: MediaQueryListEvent) => {
      setSystemColorScheme(e.matches ? "dark" : "light")
    }
    mediaQuery.addEventListener("change", listener)
    return () => mediaQuery.removeEventListener("change", listener)
  }, [])

  return {
    systemColorScheme,
  }
}
