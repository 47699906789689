import React, { useContext } from "react"

import { DMThemeGenerator } from "@supernovaio/dm"

import { ActionableRef } from "../../Actionable/Actionable.types"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

type Props = Omit<DMButtonBaseProps, "variant">

function DMButtonEmphasizedBase(props: Props, ref: ActionableRef) {
  const { luminanceBackgroundColor } = useContext(DMThemeGenerator) || {}
  const styleComputed =
    luminanceBackgroundColor === "dark"
      ? "bg-white text-black hover:opacity-70"
      : "bg-black text-white hover:opacity-80"

  const {
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: styleComputed,
      hover: "",
      focus: "focus-visible:ring-2",
      highlighted: "",
    },
  }

  return (
    <Button
      {...rest}
      ref={ref}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant="solid"
      variantStyles={variantClasses.solid}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonEmphasized = React.forwardRef(
  DMButtonEmphasizedBase
) as React.ForwardRefExoticComponent<Props>

DMButtonEmphasized.displayName = "DMButtonEmphasized"

export { DMButtonEmphasized }
